import styles from "../styles/footer.module.scss";

//footer
function Footer() {
  const handlePrivacyClick = () => {
    window.open("https://www.1stso.com/privacy", "_blank");
  };

  const handleTermsClick = () => {
    window.open("https://www.1stso.com/terms", "_blank");
  };

  return (
    <div className={styles.footer}>
      <p className={styles.footerEndText}>
        Copyright©2023-2024 1stdo.com All Rights Reserved
      </p>

      <p className={styles.footerEndText}>
        <span
          className={styles.clickableText}
          onClick={handlePrivacyClick}
          style={{ cursor: "pointer", textDecoration: "underline" }}
        >
          Privacy
        </span>{" "}
        |{" "}
        <span
          className={styles.clickableText}
          onClick={handleTermsClick}
          style={{ cursor: "pointer", textDecoration: "underline" }}
        >
          Terms
        </span>
      </p>
    </div>
  );
}

export default Footer;
